const endpoint = process.env.REACT_APP_OSS_ENDPOINT + "xiner/public/web/home-page/";

const ecommerceBg = endpoint + "ecommerce/bg.png";
const ecommerceMobileBg = endpoint + "ecommerce/mobile_bg.png";
const footerCustomerCode = endpoint + "footer/customer_service_code.png";
const footerWxWhite = endpoint + "footer/wx_white.png";
const homeAndroid = endpoint + "home/android.png";
const homeHomeBg = endpoint + "home/home_bg.png";
const homeIos = endpoint + "home/ios.png";
const homePhoneMini = endpoint + "home/phone_mini.png";
const homePhone = endpoint + "home/phone.png";
const miniAppProcess = endpoint + "mini/app_process.png";
const miniBg1 = endpoint + "mini/bg1.png";
const miniBg2 = endpoint + "mini/bg2.png";
const miniFunds = endpoint + "mini/funds.png";
const miniMobileBg1 = endpoint + "mini/mobile_bg1.png";
const miniSellGoods_1 = endpoint + "mini/sell_goods_1.png";
const miniSellGoods_2 = endpoint + "mini/sell_goods_2.png";
const miniSellGoods_3 = endpoint + "mini/sell_goods_3.png";
const miniWebUi = endpoint + "mini/web_ui.png";
const navDefaultSpacin = endpoint + "nav/default_spacin.png";
const navIcon = endpoint + "nav/nav_icon.png";

export {
    ecommerceBg,
    ecommerceMobileBg,
    footerCustomerCode,
    footerWxWhite,
    homeAndroid,
    homeHomeBg,
    homeIos,
    homePhoneMini,
    homePhone,
    miniAppProcess,
    miniBg1,
    miniBg2,
    miniFunds,
    miniMobileBg1,
    miniSellGoods_1,
    miniSellGoods_2,
    miniSellGoods_3,
    miniWebUi,
    navDefaultSpacin,
    navIcon,
}
