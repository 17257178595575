import React from "react";
import styles from "./home.module.css";
import {Space, Popover} from "antd";
import downloadSrc from "../../assets/img/home/download_code.png";
import miniCodeSrc from "../../assets/img/home/mini_code.png";
import {footerWxWhite, homeAndroid, homeHomeBg, homeIos, homePhone} from "../../OSSPictureResource";

const Home: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <img className={styles.bg} src={homeHomeBg} alt=""/>
            <div className={styles.main}>
                <div className={styles.content}>
                    <div>Spacin</div>
                    <div>开启点滴商机</div>
                    <Space className={styles.btnList}>
                        <Popover placement={"bottom"} content={<img className={styles.codePic} src={downloadSrc} alt=""/>} trigger="hover">
                            <div className={styles.btn}>
                                <img src={homeIos} alt=""/>
                                iOS版
                            </div>
                        </Popover>
                        <Popover placement={"bottom"} content={<img className={styles.codePic} src={downloadSrc} alt=""/>} trigger="hover">
                            <div className={styles.btn}>
                                <img src={homeAndroid} alt=""/>
                                Android版
                            </div>
                        </Popover>
                        <Popover placement={"bottom"} content={<img className={styles.codePic} src={miniCodeSrc} alt=""/>} trigger="hover">
                            <div className={styles.btn}>
                                <img src={footerWxWhite} alt=""/>
                                微信小程序
                            </div>
                        </Popover>
                    </Space>
                </div>
                <img className={styles.phone} src={homePhone} alt=""/>
            </div>
        </div>
    );
}

export default Home;
