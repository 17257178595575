import React from "react";
import styles from "./mini.module.css";
import trialVersionCodeSrc from "../../assets/img/mini/trial_version_code.png";
import {Space, Carousel} from "antd"
import {downloadLink} from "../../constants";
import {footerCustomerCode, homePhoneMini, miniAppProcess, miniSellGoods_1,
    miniSellGoods_2, miniSellGoods_3, miniWebUi, miniFunds, miniMobileBg1} from "../../OSSPictureResource";

const MiniSection: React.FC = () => {
    const style = {
        backgroundImage: `url(${miniMobileBg1})`
    }
    return (
        <div className={styles.miniSection} style={style}>
            <div className={styles.miniContent}>
                <Carousel autoplay>
                    <div className={styles.miniContentItem}>
                        <div className={styles.miniContentItemTitle}>独立专属的小程序</div>
                        <div className={styles.miniContentItemTips}>
                            帮助用户更好地了解企业的品牌形象、经营范围；树立企业的专业形象，提升品牌的信任度和忠诚度
                        </div>
                        <div className={styles.miniContentItemPicWrapper}>
                            <img className={styles.phoneIcon} src={homePhoneMini} alt=""/>
                        </div>
                    </div>
                    <div className={styles.miniContentItem}>
                        <div className={styles.miniContentItemTitle}>灵活自由的页面</div>
                        <div className={styles.miniContentItemTips}>
                            平台提供了一系列丰富的组件装饰，能够简单方便进行堆积木式组装；打造符合企业形象的个性化页面，提高美观度、可读性和使用价值
                        </div>
                        <div className={styles.miniContentItemPicWrapper}>
                            <img className={styles.miniTipPic} src={miniWebUi} alt=""/>
                        </div>
                    </div>
                    <div className={styles.miniContentItem}>
                        <div className={styles.miniContentItemTitle}>安全的资金流向</div>
                        <div className={styles.miniContentItemTips}>
                            交易资金直接打入商家的微信支付账户，平台没有接触资金；
                            不仅具有资金安全性，而且让买家拥有高效、便捷的支付体验
                        </div>
                        <div className={styles.miniContentItemPicWrapper}>
                            <img className={styles.miniTipPic} src={miniFunds} alt=""/>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
const AppProcessSection: React.FC = () => {
    return (
        <div className={styles.appProcessSection} id="part3">
            <div className={styles.appProcessTitle}>申请流程</div>
            <div className={styles.appProcessContent}>
                <img src={miniAppProcess} alt=""/>
            </div>
        </div>
    )
}
const SpaceSection: React.FC = () => {
    const [index, setIndex] = React.useState(0);
    const data = React.useMemo(() => {
        if (index === 0) {
            return ["01 商家卖货", "商家在小程序上架设产品并进行销售，为买家提供方便快捷的购物服务"]
        } else if (index === 1) {
            return ["02 文档管理", "帮助商家更好地处理、管理和展现各种类型的内容，为读者带来极大的便利性"]
        } else {
            return ["03 营销活动", "可以吸引潜在客户、增加用户流量、提高用户活跃度、提升品牌形象、增加销售等"]
        }
    }, [index])
    const sellGoodsSrc = React.useMemo(() => {
        if (index === 0) {
            return miniSellGoods_1;
        } else if (index === 1) {
            return miniSellGoods_2;
        } else {
            return miniSellGoods_3;
        }
    }, [index])

    return (
        <div className={styles.spaceSection}>
            <div className={styles.spaceTitle}>存放内容与商品的空间</div>
            <div className={styles.spaceBtnList}>
                <span className={`${index === 0 && styles.active}`} onClick={() => setIndex(0)}>商家卖货</span>
                <span className={`${index === 1 && styles.active}`} onClick={() => setIndex(1)}>文档管理</span>
                <span className={`${index === 2 && styles.active}`} onClick={() => setIndex(2)}>营销活动</span>
            </div>
            <div className={styles.spaceContent}>
                <div className={styles.spaceContentItem}>
                    <div className={styles.spaceContentItemTitleWrapper}>
                        <div className={styles.spaceContentItemTitle}>{data[0]}</div>
                        <div className={styles.spaceContentItemTips}>
                            {data[1]}
                        </div>
                    </div>
                    <img className={styles.sellGoodPic} src={sellGoodsSrc} alt=""/>
                </div>
            </div>
        </div>
    )
}
const AppDownloadSection: React.FC = () => {
    return (
        <div className={styles.appDownloadSection} id="part4">
            <div className={styles.appDownloadTitle}>免费体验</div>
            <div className={styles.appDownloadContent}>
                <div className={styles.appDownloadContentItem}>
                    <img src={trialVersionCodeSrc} alt=""/>
                    <div className={styles.appDownloadContentItemTitle}>Spacin小程序体验馆</div>
                    <a className={styles.appDownloadContentItemTips} href={downloadLink.trialManagementExclusiveApplet}
                       style={{color: "rgba(102, 194, 185, 1)", marginBottom: 32}}
                       target={"_blank"}>怎样体验管理专属小程序？</a>
                </div>
                <div className={styles.appDownloadContentItem}>
                    <img src={footerCustomerCode} alt=""/>
                    <div className={styles.appDownloadContentItemTitle}>联系客服，获取体验邀请码</div>
                    <a className={styles.appDownloadContentItemTips} href={downloadLink.managementPlatform}
                       target={"_blank"}>立即了解管理平台功能</a>
                </div>
            </div>
        </div>
    )
}

const Mini: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <MiniSection />
            <SpaceSection />
            <AppProcessSection />
            <AppDownloadSection />
        </div>
    );
}

export default Mini;
