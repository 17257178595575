import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../../components/pc/header";
import Footer from "../../components/pc/footer";
import Home from "../../pages/pc/home";
import Mini from "../../pages/pc/mini";
import Ecommerce from "../../pages/pc/ecommerce";
import { Skeleton } from "antd";

const PcApp: React.FC = () => {
    return (
        <>
            <Header />
            <div>
                <Router>
                    <Routes>
                        <Route path="/" element={
                            <Suspense fallback={<Skeleton/>}>
                                <Home />
                            </Suspense>
                        } />
                        <Route path="/mini" element={
                            <Suspense fallback={<Skeleton/>}>
                                <Mini />
                            </Suspense>
                        } />
                        <Route path="/ecommerce" element={
                            <Suspense fallback={<Skeleton/>}>
                                <Ecommerce />
                            </Suspense>
                        } />
                    </Routes>
                </Router>
            </div>
            <Footer />
        </>
    );
}

export default PcApp;
