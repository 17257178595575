import React from "react";
import styles from "./ecommerce.module.css";
import phoneAppSrc from "../../assets/img/ecommerce/phone_app.png";
import miniCodeSrc from "../../assets/img/home/mini_code_white.png";
import {spaceLink} from "../../constants";
import {ecommerceMobileBg, navDefaultSpacin} from "../../OSSPictureResource";

const OSS_ENDPOINT = process.env.REACT_APP_OSS_ENDPOINT;
const PlatformSection: React.FC = () => {
    const platformSectionStyle = {
        background: `url(${ecommerceMobileBg}) no-repeat center center`,
    }
    return (
        <div className={styles.platformSection} style={platformSectionStyle}>
            <div className={styles.platformSectionWrapper}>
                <div className={styles.platformSectionContentItem}>
                    <div className={styles.platformSectionContentItemTitle}>Spacin <p>内容与电商平台</p></div>
                    <div className={styles.btnList}>
                        <a href={spaceLink.web} target={"_blank"} className={styles.btn}>前往网页版</a>
                        <a href={spaceLink.merchant} target={"_blank"}
                           className={styles.btn}>商家入驻</a>
                        <a href={spaceLink.creator} target={"_blank"}
                           className={styles.btn}>成为创作者</a>
                    </div>
                </div>
                <img src={phoneAppSrc} alt=""/>
            </div>
        </div>
    );
}
const GoodsSection: React.FC = () => {
    const [showWxGoodId, setShowWxGoodId] = React.useState<number>()
    const [goods, setGoods] = React.useState([])

    React.useEffect(() => {
        fetch('/spacin/v2/mall/common/home', {
            method: 'GET',
            headers: {
                'Wechat-Mini-Appid': 'wx93418ccaf6d4fdb2'
            }
        }).then((res) => {
            return res.json()
        }).then((res) => {
            setGoods(res.data)
        })
    }, [])

    const cover = React.useCallback((product: any) => {
        const coverVideo = JSON.parse(product?.coverVideo || null)

        if (!!coverVideo) {
            return OSS_ENDPOINT + coverVideo?.cover
        } else if (product?.coverImages?.length > 0) {
            return OSS_ENDPOINT + product?.coverImages?.[0]
        } else {
            return navDefaultSpacin
        }
    }, [])

    const minPrice = React.useCallback((product: any) => {
        if (product?.skuList?.length) {
            let price = product?.skuList?.[0]?.price
            product?.skuList?.forEach((sku: any) => {
                if (Number(sku?.price) < Number(price)) {
                    price = sku?.price
                }
            })
            return price
        } else {
            return product?.price
        }
    }, [])

    const minOriginPrice = React.useCallback((product: any) => {
        if (product?.skuList?.length) {
            let originPrice = product?.skuList?.[0]?.originPrice
            product?.skuList?.forEach((sku: any) => {
                if (Number(sku?.originPrice) < Number(originPrice)) {
                    originPrice = sku?.originPrice
                }
            })
            return originPrice
        } else {
            return product?.originPrice
        }
    }, [])

    const onClick = React.useCallback((id: number) => {
        setShowWxGoodId(id)
    }, [showWxGoodId])
    return (
        <div className={styles.goodsSection}>
            <div className={styles.goodsSectionTitle}>
                商城精选
            </div>
            <div className={styles.goodsSectionContent}>
                {
                    goods?.filter((v: any) => {
                        return !v?.product?.deleted && v?.product?.status == 1
                    }).map((item: any) => {
                        return (
                            <div className={styles.goodsSectionContentItem} key={item?.product?.id}
                                 onClick={() => onClick(item?.product?.id)}>
                                <div className={styles.goodsSectionContentItemCover}>
                                    <img src={cover(item?.product)}/>
                                </div>
                                <div className={styles.goodsSectionContentItemName}>
                                    {item?.product?.title}
                                </div>
                                <div className={styles.goodsSectionContentItemPrice}>
                                    <div className={styles.goodsSectionContentItemSellingPrice}>
                                        ￥{minPrice(item?.product)}
                                    </div>
                                    { minPrice(item?.product) !== minOriginPrice(item?.product) && (
                                        <div className={styles.goodsSectionContentItemOriginalPrice}>
                                            ￥{minOriginPrice(item?.product)}
                                        </div>
                                    )}
                                </div>
                                {
                                    showWxGoodId == item?.product?.id && (
                                        <div className={styles.goodInfo}>
                                            <img src={miniCodeSrc} alt=""/>
                                            <span className={styles.goodInfoTip}>微信扫一扫</span>
                                            <span>进入Spacin小程序</span>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}
const Ecommerce: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <PlatformSection/>
            <GoodsSection/>
        </div>
    );
}

export default Ecommerce;
