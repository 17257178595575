import React from "react";
import styles from "./home.module.css";
import wxSrc from "../../assets/img/footer/wx.png";
import {Space, Popover} from "antd";
import downloadSrc from "../../assets/img/home/download_code.png";
import miniCodeSrc from "../../assets/img/home/mini_code.png";
import {homeAndroid, homeHomeBg, homeIos, homePhone} from "../../OSSPictureResource";

const Home: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <img className={styles.bg} src={homeHomeBg} alt=""/>
            <div className={styles.main}>
                <img className={styles.phone} src={homePhone} alt=""/>
                <div className={styles.content}>
                    <div>Spacin</div>
                    <div>开启点滴商机</div>
                    <Space>
                        <Popover placement={"bottom"} content={<img className={styles.overPic} src={downloadSrc} alt=""/>} trigger="hover">
                            <div className={styles.btn}>
                                <img src={homeIos} alt=""/>
                                iOS版
                            </div>
                        </Popover>
                        <Popover placement={"bottom"} content={<img className={styles.overPic} src={downloadSrc} alt=""/>} trigger="hover">
                            <div className={styles.btn}>
                                <img src={homeAndroid} alt=""/>
                                Android版
                            </div>
                        </Popover>
                        <Popover placement={"bottom"} content={<img className={styles.overPic} src={miniCodeSrc} alt=""/>} trigger="hover">
                            <div className={styles.btn}>
                                <img className={styles.wxBtn} src={wxSrc} alt=""/>
                                微信小程序
                            </div>
                        </Popover>
                    </Space>
                </div>
            </div>
        </div>
    );
}

export default Home;
