import React from "react";
import styles from "./footer.module.css";
import policeSrc from "../../assets/img/footer/jingcha.png";
import wxSrc from "../../assets/img/footer/wx.png";
import wxCodeSrc from "../../assets/img/footer/wx_code.png";
import customerServiceSrc from "../../assets/img/footer/customer_service.png";
import {Popover} from "antd";
import {footerLink} from "../../constants";
import {footerCustomerCode} from "../../OSSPictureResource";

const Footer: React.FC = () => {
    return (
        <footer className={styles.wrapper}>
            <span style={{marginRight: 8}}>Copyright © 2021-2023 广州轻变量信息科技有限公司</span>
            <span className={styles.infoItem}>
                <a href={footerLink.integrated} target={"_blank"}>粤ICP备2021065541号-1</a>
            </span>
            <img className={styles.policeIcon} src={policeSrc} alt=""/>
            <span className={styles.infoItem}>
                <a href={footerLink.record}
                   target={"_blank"}>
                    粤公网安备 44010602009917号</a>
            </span>
            <span className={styles.infoItem}>
                <a href={footerLink.license} target={"_blank"}>增值电信业务经营许可证：粤B2-20221438</a>
            </span>

            <div className={styles.protocol}>
                <a href={footerLink.userAgreement} target={"_blank"}>用户协议</a>
                ｜
                <a href={footerLink.privacyPolicy} target={"_blank"}>隐私协议</a>
            </div>

            <Popover content={
                <div className={styles.codeWrapper}>
                    <img src={wxCodeSrc} alt=""/>微信公众号</div>
            } trigger="hover">
                <img className={styles.wxIcon} src={wxSrc} alt=""/>
            </Popover>
            <Popover content={
                <div className={styles.codeWrapper}>
                    <img src={footerCustomerCode} alt=""/>联系客服</div>
            } trigger="hover">
                <img className={styles.icon} src={customerServiceSrc} alt=""/>
            </Popover>
        </footer>
    );
}

export default Footer;
