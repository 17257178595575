import React from "react";
import { useMediaQuery } from "react-responsive";
import { Button, ConfigProvider } from 'antd';

import MobileApp from "./pages/mobile";
import PcApp from "./pages/pc";

const App: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
      <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#66C2B9',
            },
          }}
      >
        {isMobile ? <MobileApp /> : <PcApp />}
    </ConfigProvider>
  );
};

export default App;
