import React from "react";
import styles from "./header.module.css";
import {Menu} from "antd";
import logo from "../../assets/img/nav/logo.png";
import {spaceLink} from "../../constants";

const Header: React.FC = () => {
    const [current, setCurrent] = React.useState(window.location.pathname.split("/")?.[1] || "home");

    const onClick = (e: any) => {
        setCurrent(e.key);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.logoWrapper}>
                    <img className={styles.logo} src={logo} alt=""/>
                    <span>Spacin</span>
                </div>
                <Menu className={styles.navList} mode="horizontal" onClick={onClick} selectedKeys={[current]}>
                    <Menu.Item key="home">
                        <a href="/">首页</a>
                    </Menu.Item>
                    <Menu.SubMenu key="mini" title="专属小程序">
                        <Menu.Item key="mini">
                            <a href="/mini">介绍</a>
                        </Menu.Item>
                        <Menu.Item key="miniPart3">
                            <a href="/mini#part3">申请流程</a>
                        </Menu.Item>
                        <Menu.Item key="JVOODN">
                            <a target={"_blank"} href={spaceLink.price}>了解价格</a>
                        </Menu.Item>
                        <Menu.Item key="console">
                            <a target={"_blank"} href={spaceLink.managementPlatform}>管理平台</a>
                        </Menu.Item>
                        <Menu.Item key="Qq556Q">
                            <a target={"_blank"} href={spaceLink.agent}>成为代理商</a>
                        </Menu.Item>
                        <Menu.Item key="miniPart4">
                            <a href="/mini#part4">我要体验</a>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="ecommerce" title="内容电商平台">
                        <Menu.Item key="ecommerce">
                            <a href="/ecommerce">介绍</a>
                        </Menu.Item>
                        <Menu.Item key="space">
                            <a target={"_blank"} href={spaceLink.web}>网页版</a>
                        </Menu.Item>
                        <Menu.Item key="JdXXoJ">
                            <a target={"_blank"} href={spaceLink.merchant}>商家入驻</a>
                        </Menu.Item>
                        <Menu.Item key="NeOO4Q">
                            <a target={"_blank"} href={spaceLink.creator}>成为创作者</a>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="云服务" key="云服务">
                        <Menu.Item key="NRjjkN">
                            <a target={"_blank"} href={spaceLink.officialWebsiteDevelopment}>官网开发</a>
                        </Menu.Item>
                        <Menu.Item key="Jn22rJ">
                            <a target={"_blank"} href={spaceLink.appCustomization}>App定制</a>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="生态服务" key="生态服务">
                        <Menu.Item key="JOBx4Q">
                            <a target={"_blank"} href={spaceLink.itQualification}>IT资质</a>
                        </Menu.Item>
                        <Menu.Item key="N50oKJ">
                            <a target={"_blank"} href={spaceLink.shortVideoProduction}>短视频制作</a>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="J2qKwQ">
                        <a target={"_blank"} href={spaceLink.caseDisplay}>案例展示</a>
                    </Menu.Item>
                    <Menu.Item key="J04mXJ">
                        <a target={"_blank"} href={spaceLink.manual}>使用手册</a>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
}

export default Header;
