export const footerLink = {
    integrated: "https://beian.miit.gov.cn/#/Integrated/index",
    record: "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009917",
    license: "https://tsm.miit.gov.cn/dxxzsp/",
    userAgreement: "https://spacin-oss.spacin.cn/xiner/public/global/user_agreement.html",
    privacyPolicy: "https://spacin-oss.spacin.cn/xiner/public/global/privacy_policy.html",
}

export const spaceLink = {
    price: "https://www.spacin.cn/post/JVOODN",
    managementPlatform: "https://console.spacin.cn",
    agent: "https://www.spacin.cn/post/Qq556Q",
    web: "https://www.spacin.cn/space",
    merchant: "https://www.spacin.cn/post/JdXXoJ",
    creator: "https://www.spacin.cn/post/NeOO4Q",
    officialWebsiteDevelopment: "https://www.spacin.cn/post/NRjjkN",
    appCustomization: "https://www.spacin.cn/post/Jn22rJ",
    itQualification: "https://www.spacin.cn/post/JOBx4Q",
    shortVideoProduction: "https://www.spacin.cn/post/N50oKJ",
    caseDisplay: "https://www.spacin.cn/space/J2qKwQ",
    manual: "https://www.spacin.cn/space/J04mXJ",
}

export const downloadLink = {
    trialManagementExclusiveApplet: "https://www.spacin.cn/post/JAVbvN",
    managementPlatform: "https://www.spacin.cn/post/JAVbvN",
}
